<template>
    <div class="product">
        <div class="topbg">
            <img :src="topImg" alt="">
        </div>
        <div class="midbg">
            <div class="main">
                <div class="topstr">
                    <h2>{{current.title}}</h2>
                    <div>{{current.titlestr}}</div>
                </div>
                <div class="midimg">
                    <img :src="current.midImg" alt="">
                </div>
            </div>
        </div>
        <div class="bottombg">
            <div class="bottommain">
                <h2>产品特性</h2>
                <div class="bottomimg">
                    <img :src="current.bottomImg" alt="">
                </div>
                <div class="bottomstr">
                    <div class="bottomstrbox"
                        v-for="(item,index) in current.bottomlist" :key="index">
                        <img :src="item.icon" alt="">
                        <h4>{{item.title}}</h4>
                        <p>{{item.str}}</p>
                    </div>
                </div>
            </div>
        </div>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import foots from '@/components/mobile/foot'
import hotline from '@/components/mobile/hotline.vue'
export default {
    components:{
        foots,
        hotline
    },
    data(){
        return{
            topImg:require('@/assets/img/mobileImg/productImg/bg1.png'),
            current:{},
            content:[
                {
                    id:1,
                    title:'医废管理',
                    titlestr:'泰科医键通为医疗后勤自主研发了一套智能医疗废物转运系统（简称医废转运系统）该系统对医疗垃圾进行全程管理，通过规范医废转运流程，实现医废称重一体，从而提升医废标签清晰、规范，数据准确性、来源可追溯，数据云存储，打印导出存储，数据统计一目了然',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/yf_pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'专业团队打造',
                            str:'系统由拥有20年医院后勤服务管理经验的专业团队倾力打造',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'操作便捷',
                            str:'操作的便捷性，界面设计按后勤服务人群定制，提供语言播报、颜色分类标签、色块明显等确保易用性',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'自研医废专用硬件',
                            str:'自主研发硬件，更贴合医院实际使用情况，提高收运效率',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'流程丰富',
                            str:'提供多种收运模式和多种电子交接方式，从院内至院外都可以覆盖',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'数据分析',
                            str:'各科室数据自动汇总重量、数量及合计数据，可选择条形图或饼图，可导出数据',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'异常数据监控',
                            str:'通过监控中心数据随时掌握各环节医疗废物的情况，系统异常标记，便于监管者及时发现异常',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/7.png'),
                            title:'移动数据',
                            str:'可在APP和微信公众号实时查看今日产生医废数据及各科室数据、未交接数据，实现随时随地查看数据',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/8.png'),
                            title:'可回收管理',
                            str:'支持对可回收医废独立管理，也可包含在内统一管理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/9.png'),
                            title:'与无害化、卫健委对接',
                            str:'提供与卫健委、无害化处理中心接口对接，实现数据互通',
                        }
                    ],
                },
                {
                    id:2,
                    title:'输送系统',
                    titlestr:'输送系统能有效地提高医院的医疗运送效率，避免出现人员忙闲不均及指令不清现象，提高输送效率，实现临床科室一键下单、输送员工快捷接单调动中心全盘掌控输送任务完成情况',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1069.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/ss／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'集中管理',
                            str:'管理人员网页端统一登记、统一派工和集中监控随时掌握输送员的任务状态，随时查看医嘱及护士站的输送任务单完成情况',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'来电下单',
                            str:'贴心电话服务咨询登记，客服接到电话后只需要简单操作（选择式）即可快捷下单发布指令，电话语音内容，可回放',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'语音播报、催单',
                            str:'新单自动语音播报提醒派单，并提供催单、转单功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'移动输送',
                            str:'输送员工手机APP、微信查看输送工单情况、在线接单、完成',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'数据存储完整',
                            str:'每条输送数据完整记录，输送下单科室、完成人、耗时等一目了然',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'丰富数据统计',
                            str:'多维度分析含：任务来源分布、完成时效、输送类别、下单排名等，提供饼图和条形图，可以导出Excel和图片格式',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/7.png'),
                            title:'动漫输送培训',
                            str:'设计动漫操作视频培训，简单易懂、培训关键点清晰，容易操作',
                        },
                    ],
                },
                {
                    id:31,
                    title:'维修报障',
                    titlestr:'维修报障系统包含完整的维修工单管理、实现维修派工自动化，支持智能抢单，网页模式与APP模式结合派工，提高了派工管理人员的工作效率。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1070.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/wx／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'快捷报修',
                            str:'支持临床科室在网页/微信上发起报修任务和查看完成进度及详细情况',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'集中管理',
                            str:'集中监控维修工单、信息快速查询能够按照维修单标题、发起人、受理人、创建时间等多维度的搜索条件查询工单',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'来电下单',
                            str:'贴心电话服务咨询登记，客服接到电话后只需要简单操作（选择式）即可快捷下单发布指令，电话语音内容，可回放',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'自动派单',
                            str:'新单自动语音播报提醒派单，支持自动派单，开启自动派单后，系统有序的自动派发或指定派发给空闲状态的维修员',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'抢单派单',
                            str:'智能抢单池，支持新单自动转入，实现维修人员灵活抢单，提升员工积极性，提升服务完成率，打造维修人员绩效管理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'移动维修',
                            str:'维修班组人员手机APP查看维修工单情况、在线接单、完成，提升维修服务智能化管理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/7.png'),
                            title:'数据统计',
                            str:'驾驶舱模式智能统计大盘，17种数据报表可查，支持自定义报表字段、多维度统计如：维修统计总量、完成率、来源，一周数据对比等，支持数据导出数据服务',
                        },
                    ],
                },
                {
                    id:32,
                    title:'维修巡检',
                    titlestr:'维修巡检系统通过网页端建立巡检计划，手机app蓝牙巡检，维修管理人员根据企业的设备巡检规范及巡检内容，统一设立巡检周期，有效减少设备因人为因素而带来的错检或漏检问题，变被动等待报修为主动巡检维护，变无序报修工作为有序计划工作',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1071.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/xj／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'建立巡检计划',
                            str:'可按照巡检标准制定巡检表，可设置巡检内容，巡检周期和巡检人员，有备注功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'定位巡检',
                            str:'手机打开app蓝牙定位巡检，巡检人员需到达指定地点后才可进行表格填写，实现移动巡检、确保检查真实性',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'异常数据提醒',
                            str:'异常数据高亮标记、并可填写异常原因和上传异常图片，有发送警报提醒至管理人员手机功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'巡检数据概览',
                            str:'根据每日巡检数据，查看巡检计划是否按期执行，及巡查月度正常、异常情况，为管理部门提供有效的监督管理的手段和方法',
                        },
                    ],
                },
                {
                    id:33,
                    title:'后勤维保',
                    titlestr:'后勤设备维护保养（简称后勤维保），采用绑定二维码方式，针对需要周期维护的设备，可定制维保周期实现移动维护设备信息，扫码即可查看到该科室有哪些设备',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/hq／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'周期性维保计划',
                            str:'可帮助职能部门建立电子设备档案和该设备设施周期性的维护保养计划（维保计划），通过计划的合理安排和有效执行有利于延长设备寿命、降低维护成本',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'定制设备二维码',
                            str:'可根据设备名称、地址、图片等自定义生成二维码信息微信扫二维码即可查看设备信息、设备保养情况、维保记录、并有设备快捷报修功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'移动维保',
                            str:'维保执行人收到系统推送的维保任务，只需简单操作，即可完成确认维护，可查看保养周期等信息',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'维保情况查看',
                            str:'管理人员查看维保计划即可知道哪些设备是否维保，维保次数、及错过次数，为管理部门提供了有效的监督方法',
                        },
                    ],
                },
                {
                    id:4,
                    title:'保洁系统',
                    titlestr:'保洁系统主要针对管理人员的日常巡查及卫生自查项目可针对保洁项目自定义的执行标准，其中引入了微信保洁下单的模式，如用户发现突发事件可进行拍照下单，区域主管会对问题进行及时处理并反馈结果做到对突发情况的及时响应及时处理的服务要求',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1072.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/bj／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'问题申报',
                            str:'可在微信和APP上进行保洁问题申报，员工APP有新单提醒，可随时接单，做到对突发情况及时响应处理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'问题自查',
                            str:'针对管理人员的日常巡查及卫生自查项目，可按照执行标准设置巡查内容，管理人员巡查完成可在APP内登记巡查结果',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'规范巡查',
                            str:'管理部门每日可以在系统中看到巡查次数、巡查中的问题及每日的保洁质量',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'保养记录',
                            str:'外墙养护、地面打蜡等服务也会被记录在保洁系统中',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'数据分析',
                            str:'提供数据分析含用户保洁申报巡查情况分析、人员发单量、问题多发区域汇总统计，项目管理巡查情况等、针对问题区域多发情况管理人员可进行合理的人员调配及安排，有效的提高了监管质量',
                        },
                    ],
                },
                {
                    id:5,
                    title:'人员管理',
                    titlestr:'人员管理系统是一套可自定义的人员管理工具人员档案资料信息化，在内网可自行查询并与考勤和社保公积金联动，可查看人员相应的每月考勤出勤情况和社保情况建立人员档案，离职入职时可以查看该人员在其他项目点的情况，用作企业内部档案管理',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1073.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/ry／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'人员电子档案',
                            str:'实现员工入职、离职全过程电子化管理、本月人员异动情况随时可查，支持个性化定制人员信息档案模板',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'考勤联动',
                            str:'与考勤联动，在个人档案内即可查看该员工月度出勤、请假、迟到、早退情况',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'社保公积金',
                            str:'可查看人员每月社保公积金缴纳情况，有社保公积金抽查功能，可上传凭证',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'智能报表',
                            str:'支持多维度查看人员统计数据如:工龄分布、学历分布、持证情况、岗位分布等、按需生成饼图、条形图，可下载统计图片和报表为决策分析提供依据',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'人员在线考核',
                            str:'提供在线考核功能，可发送至企业人员的手机微信在线答题，在线出成绩，提供错题提示，可多次答题，有效提高了人员对考核内容熟练掌握',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'考核管理',
                            str:'管理人员可在后台下载员工考核成绩，可查看人员答题次数和最高得分，了解每个员工对考核内容熟悉情况，支持自定义考核问卷内容',
                        },
                    ],
                },
                {
                    id:6,
                    title:'考勤打卡',
                    titlestr:'考勤打卡系统，支持上下班打卡、外勤打卡和请假功能，员工可在app上绑定人脸识别，系统自动定位当前地址进行打卡，需在设置的医院范围内才可打卡成功打卡后，管理人员可在网页系统内查看每日出勤打卡情况，含上下班时间信息、出勤人数、未出勤人数，可导出excel报表',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1074.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/kq／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'快速打卡',
                            str:'支持定位打卡，需在设置的医院范围内才可打卡成功，有人脸识别打卡功能，支持加班打卡、值班打卡和外勤打卡',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'跨夜打卡',
                            str:'支持跨夜打卡，可用于排班是夜班人员的考勤打卡',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'个性化关怀',
                            str:'个性化员工打卡关怀，有温度的工作氛围，提升工作幸福感，可设置打卡提醒，减少漏打卡行为',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'考勤排班',
                            str:'通过排班计划自动计算人员迟到、早退异常出勤状况，有固定排班和自由排班功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'请假申请',
                            str:'可在手机APP发起请假申请，查看申请审批结果，管理人员可移动审批请假单',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'数据统计',
                            str:'提供月度考勤汇总和个人出勤详细报表，可查看每日所有人员迟到、早退、请假等打卡异常数据',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/7.png'),
                            title:'人员健康登记',
                            str:'新冠疫情期间，支持在线登记每日健康状况，异常数据可发送提醒至管理人员手机',
                        },
                    ],
                },
                {
                    id:7,
                    title:'停车场管理',
                    titlestr:'停车场收费系统，搭配停车场硬件设备可实现对车辆进出场管理，有详细记录可查，可自定义设定收费标准，提供VIP车辆功能和车辆停车情况数据分析',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1075.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/tc／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'定制收费标准',
                            str:'可设定按医院和物价局规定的进场免费时间和实际看病情况自行设定收费标准，有0元免费放行和不足1小时按1小时收费等功能',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'进出场管理',
                            str:'车辆进场和出场系统自动识别车辆信息及停车时长，自动计算需收费金额，有优惠放行和人工放行操作',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'硬件设备',
                            str:'提供停车场相关硬件设备采购安装和配置如：户外大显示屏显示内容可按需定制，用于提示该停车场剩余车位等信息',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'VIP车辆管理',
                            str:'针对VIP车辆，可实现自动抬杆离场，也可针对短期VIP车辆，添加临时VIP车牌，自由设定VIP有效期',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'数据分析',
                            str:'完善的数据统计，可以大大地规避了外来车辆占用公共资料的情况，数据自动分析车牌所属省份、过夜最多车辆省份、24小时出入口数据、优惠放行数据等',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/6.png'),
                            title:'在线缴费',
                            str:'支持车主在微信上提交车辆登记，进行停车缴费',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/7.png'),
                            title:'移动停车场管理',
                            str:'支持管理人员在APP上操作车辆出入口抬杆，VIP管理，设置停车优惠券等',
                        },
                    ],
                },
                {
                    id:8,
                    title:'智能卫生间引导系统',
                    titlestr:'智能卫生间引导系统利用现代信息技术与感应硬件相结合，可实时监控、记录厕所的温度、湿度、空气质量、人流量、sos按键求助及能源消耗评估等，用户可实时查看各楼层的厕格使用情况，为用户如厕提供便捷、减少排队等待，拥挤的情况同时还设立了“SOS按键求助”和“报修按键”，按键后直接通知到医院调度中心实现了对公厕的精细化管理，我们也将持续努力，为用户提供人性化、优质、舒适的服务。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/cs／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'厕所环境监测',
                            str:'对卫生间异味，温度湿度等数据进行实时监测，遇到数据超标，异常情况可及时通知保洁人员进行处理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'厕格使用情况监测',
                            str:'实时显示附近空闲卫生间有效解决繁忙区域排队长， 如厕难，厕位忙闲不均的问题',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'SOS求助管理',
                            str:'SOS求助按键，大屏幕显示发出求助信息的厕位，并同时通知调度中心，通知工作人员前去查看',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'厕所数据统计',
                            str:'分析厕所用水总量、每月用水量、每日用水量，统计的数据类型都可按时间查询数据异常系统会出现提醒，方便管理人员判断设备故障情况',
                        },
                    ],
                },
                {
                    id:9,
                    title:'服务满意度管理',
                    titlestr:'服务满意度管理系统（简称满意度调查），可实现管理人员对后勤部门内部考核指标编制、对外包服务商考核标准制定，以及对临床满意度调查的反馈统计，调查对象可在微信上收到调查问卷通知，可在线填写调查问卷、提交评价，查看已填写信息等，轻松完成科室、患者满意度调查等各类在线问卷调查，替代了过往纸质调查的方式。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/fw／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'问卷模板自定义',
                            str:'自定义调查问卷模板模板，可按各部门考核标准自定义设置问卷问题和答题选项分值，实现多部门问卷考核任务都可在线创建问卷，可指定发送至微信人员，轻松完成科室、患者满意度调查等各类在线问卷调查',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'在线填写问卷',
                            str:'支持微信在线填写满意度调查问卷，改善传统纸质打印再回收问卷流程、节省人力成本，提高工作效率',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'数据自动统计',
                            str:'调查结果自动生成数据分析，一键导出反馈意见、平均分、调查统计数据为EX报表可将报表发送至相关责任部门进行整改，形成服务监管闭环管理',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'实时反馈调查结果',
                            str:'管理人员可在APP内查看满意度调查数据统计，可查看每项问题各科室人员评分情况，含评分百分比、参与科室、平均分、建议等',
                        },
                    ],
                },
                {
                    id:10,
                    title:'保安巡逻',
                    titlestr:'保安巡逻指在指定的时间内巡查指定区域，在系统的辅助下保安人员手机APP到点定位即可完成巡逻，支持发现维修问题等其他事件在线发起问题单，交由其他部门及时处理，管理人员可在后台查看巡逻记录是否按要求完成，有效监督巡逻质量，有巡逻事件管理查看功能。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/ba／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'定位巡逻',
                            str:'巡逻人员APP作业，蓝牙定位巡逻，到达指定位置后自动打卡成功，可前往下一个巡逻点，操作简单快捷',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'突发事件记录汇报',
                            str:'针对突发事件如治安事件、消防事件、停车场事件、好人好事等，支持拍照上传随时记录问题',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'维修申报',
                            str:'巡逻发现的问题如设备故障维修问题，可发起维修申报，交由其他部门处理可随时追踪维修反馈',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'巡逻轨迹',
                            str:'巡逻结束后自动生成巡逻路径，协助管理人员监管，保障巡逻质量',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'数据查询',
                            str:'数据云端存储，管理人员后台查看数据，支持按时间查询按人员查询等，方便快捷解决以往纸质存储保存不易，及查找资料不便问题',
                        },
                    ],
                },
                {
                    id:11,
                    title:'被服管理',
                    titlestr:' 被服管理系统支持用户在线进行被服申领下单至洗涤中心，洗涤公司确认出洗数量并根据数量出货配送科室，可在线核对确认配送数量，支持在微信下单、微信确认数量，查看每日数据报表等，解决了以往需要手工签收填写的误差问题。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/bf／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'在线申领下单',
                            str:'用于被服下单，填写申领数量和规格，可查询已下单信息和定制下单模板',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'被服发放情况查看',
                            str:'支持查看各科室被服预定申领订单发放状态，可进行打印申领订单和发放支持按时间查询',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'在线出洗确认',
                            str:'洗涤中心助理查看出洗的被服数量和订单详情，审核确认出洗数量，支持打印出洗单',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'被服统计',
                            str:'统计可按科室查询申领数量、出洗数量和配送数量，可查看数据异常详情，支持按时间搜索查询',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/5.png'),
                            title:'移动被服',
                            str:'科室护士可在微信上填写被服申领数量和规格，支持绑定科室，管理人员可在微信上查看所有出洗、配送异常订单和异常数量',
                        },
                    ],
                },
                {
                    id:12,
                    title:'垃圾分类小游戏',
                    titlestr:'垃圾分类游戏是一款图文答题微信小游戏，方便市民记忆垃圾名称和垃圾类型，垃圾分类按照广州市垃圾分类要求制定分为可回收物、餐厨垃圾、有害垃圾、其他垃圾，游戏囊括了常见生活垃圾进行垃圾分类，采用图文答题闯关方式，用户在玩游戏的过程中就能了解垃圾分类的知识。',
                    midImg:require('@/assets/img/pcImg/indexImg/list2/组1068.png'),
                    bottomImg:require('@/assets/img/pcImg/productImg/game／pic.png'),
                    bottomlist:[
                        {
                            icon:require('@/assets/img/pcImg/productImg/1.png'),
                            title:'趣味答题',
                            str:'获取垃圾分类知识还能这样玩？答题闯关模式：提供4种闯关题型，真实垃圾+动画风格界面设计，搭配答题音效，有趣好玩，答题成功即可进入下一关卡，帮助用户尽快熟悉常见垃圾分类知识，越熟悉垃圾分类得分越高',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/2.png'),
                            title:'成绩榜单',
                            str:'查看自己得分成绩和排名情况，好友榜单内显示全部好友已参赛的排名，答题数越多排名越靠前，提升用户互动性和积极性，还可查看全服榜排名',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/3.png'),
                            title:'垃圾百科',
                            str:'可在垃圾百科查看常见垃圾如何分类，四大垃圾类目下对应的垃圾种类、图片、名称等，方便用户系统性学习垃圾分类知识',
                        },
                        {
                            icon:require('@/assets/img/pcImg/productImg/4.png'),
                            title:'企业活动登记',
                            str:'为合作企业免费提供员工参赛活动登记，可用于活动结束颁发奖项核对个人信息的真实性等',
                        },
                    ],
                },
            ],
            
        }
    },
    // created(){
    //     this.current = this.content[8]
    // }
    watch:{
        $route:{
            handler(){
                // console.log(this.$route.params.id);
                for(var i=0;i<this.content.length;i++){
                    if(this.content[i].id == this.$route.params.id){
                            this.current = this.content[i]
                    }
                }
            },
            immediate: true
        },
    },
}
</script>

<style lang="less" scoped>
.product{
    width: 23.4375rem;
    .topbg{
        width: 23.4rem;
        position: relative;
        z-index: -5;
        height: 25rem;
        img{
            width: 100%;
        }
    }
    .midbg{
        margin: -20.5rem 0 0 0;
        .main{
            .topstr{
                h2{
                    font-size: 1.75rem;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: center;
                }
                div{
                    width: 19.6875rem;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.5rem;
                    color: #FFFFFF;
                    margin: 1.125rem auto 6.5rem;
                }
            }
            .midimg{
                width: 23.4375rem;
                height: 16.875rem;
                background: #FFFFFF;
                box-shadow: 0rem 0.9375rem 2.6875rem rgba(0, 0, 0, 0.05);
                border-radius: 0.875rem;
                // margin: 0 auto;
                img{
                    width: 15rem;
                    margin: 2.3rem 0 0 4rem;
                }
            }
        }
    }
    .bottombg{
        width: 23.4375rem;
        background: #FAFBFE;
        border-radius: 0.1875rem;
        .bottommain{
            h2{
                font-size: 1.125rem;
                font-weight: bold;
                color: #31384E;
                text-align: center;
                margin: 1.875rem 0 0 0;
            }
            .bottomimg{
                margin: 1.875rem 0 0 0.9375rem;
                img{
                    width: 21.5625rem;
                    height: 13.75rem;
                    background: #FFFFFF;
                    box-shadow: 0px 0.625rem 2.625rem rgba(23, 35, 75, 0.08);
                    border-radius: 0.375rem;
                }
            }
            .bottomstr{
                padding: 0.625rem 0;
                .bottomstrbox{
                    width: 19.6875rem;
                    margin: 1.25rem auto;
                    img{
                        float: left;
                        margin-right: 0.625rem ;
                    }
                    h4{
                        font-size: 1rem;
                        font-weight: bold;
                        color: #31384E;
                        margin-bottom: 0.3125rem ;
                    }
                    p{
                        margin-left: 1.875rem;
                        font-size: 0.75rem;
                        font-weight: 400;
                        line-height: 1rem;
                        color: #697598;
                    }
                }
            }
        }
    }
    /deep/ .foot{
    background-color: #fff;
    }
}
</style>